import { useState } from "react";
import { BasicPage } from "../common/BasicPage/BasicPage";
import { Typography } from "../common/Typography/Typography";
import { Box, Card, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../generated";
import ProfileAvatarIcon from "../common/ProfileAvatarIcon/ProfileAvatarIcon";
import {
  ButtonDefault,
  ButtonPrimary,
  classes,
  StyledCorporateFare,
  StyledTextField,
  StyledTypography,
} from "./style";
import useUserDetails from "./hooks/useUserDetails";

export const MyProfilePage = () => {
  const { data } = useQuery(GetCurrentUserInfoDocument);
  const {
    userDetailsState,
    updateDetailsValue,
    toggleDetailsEdit,
    submitUserDetails,
    loadingMutateDetails,
  } = useUserDetails({ data });

  const [userPasswordState, setUserPasswordState] = useState({
    password: "",
    confirmPassword: "",
    isEdit: false,
  });

  const fullName = `${userDetailsState?.name?.firstName || ""} ${
    userDetailsState?.name?.lastName || ""
  }`;

  const emailAddress = data?.currentUser?.emailAddress;

  const organizationNames = data?.currentUser?.workspaces?.reduce(
    (accum, workspace) => {
      const orgName = workspace?.ownerOrganizationName;
      if (orgName) {
        if (accum === "") {
          return orgName;
        } else {
          return `${accum}, ${orgName}`;
        }
      }
      return accum;
    },
    ""
  );

  return (
    <BasicPage
      leftHeaderElements={<Typography variant="h4">My profile</Typography>}
      content={
        <Card sx={{ pt: 6, pl: 8, pr: 8, pb: 10 }}>
          <Typography mb={4} variant="h6">
            Profile information
          </Typography>

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                NAME
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              {userDetailsState?.name?.isEdit ? (
                <>
                  <StyledTextField
                    value={userDetailsState.name.firstName}
                    onChange={(e) => {
                      updateDetailsValue("name", "firstName", e.target.value);
                    }}
                  />
                  <StyledTextField
                    sx={{ marginLeft: "1rem" }}
                    value={userDetailsState.name.lastName}
                    onChange={(e) =>
                      updateDetailsValue("name", "lastName", e.target.value)
                    }
                  />
                  <Box display="flex" ml={5} gap={3}>
                    <ButtonDefault
                      onClick={() => toggleDetailsEdit("name", false)}
                    >
                      Cancel
                    </ButtonDefault>
                    <ButtonPrimary
                      disabled={loadingMutateDetails}
                      onClick={() => submitUserDetails()}
                    >
                      Update
                    </ButtonPrimary>
                  </Box>
                </>
              ) : (
                <>
                  <ProfileAvatarIcon
                    name={fullName}
                    email={emailAddress}
                    width={24}
                    height={24}
                  />
                  <Typography variant="body2" ml={1}>
                    {fullName}
                  </Typography>

                  <Box ml={5}>
                    <IconButton
                      onClick={() => toggleDetailsEdit("name", true)}
                      size="small"
                      className="edit-button"
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                EMAIL
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" ml={1}>
                {emailAddress}
              </Typography>
            </Box>
          </Box>

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                Password
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" ml={1}>
                *******
              </Typography>
            </Box>
          </Box>

          <Box mb={6}>
            <Box mb={2}>
              <StyledTypography
                variant="overline"
                className={classes[classes.label]}
              >
                ORGANIZATIONS
              </StyledTypography>
            </Box>
            <Box display="flex" alignItems="center">
              <StyledCorporateFare />
              <Typography variant="body2">{organizationNames}</Typography>
            </Box>
          </Box>

          {/*<Box mb={6}>*/}
          {/*  <Box mb={2}>*/}
          {/*    <Typography color={palette.gray.dark} variant="overline">*/}
          {/*      CONTACT*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Link fontWeight="bold" variant="body2" to="/#">*/}
          {/*      email@email.com*/}
          {/*    </Link>*/}
          {/*  </Box>*/}
          {/*</Box>*/}

          {/*<Box>*/}
          {/*  <Box mb={2}>*/}
          {/*    <Typography color={palette.gray.dark} variant="overline">*/}
          {/*      ACCOUNT CREATED*/}
          {/*    </Typography>*/}
          {/*  </Box>*/}
          {/*  <Box>*/}
          {/*    <Typography variant="body2">1/23/22</Typography>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
        </Card>
      }
    />
  );
};
