import { useEffect, useState } from "react";
import {
  GetCurrentUserInfoDocument,
  GetCurrentUserInfoQuery,
  UpdateUserDetailsDocument,
} from "../../generated";
import { useMutation } from "@apollo/client";
import { displayToast } from "../../common/Toast/toast";

type Props = { data: GetCurrentUserInfoQuery | undefined };

const initialState = (data: GetCurrentUserInfoQuery | undefined) => ({
  name: {
    firstName: data?.currentUser?.firstName || "",
    lastName: data?.currentUser?.lastName || "",
    isEdit: false,
  },
});

const useUserDetails = ({ data }: Props) => {
  const [update, { loading }] = useMutation(UpdateUserDetailsDocument, {
    refetchQueries: [GetCurrentUserInfoDocument],
  });
  const [userDetailsState, setUserDetailsState] = useState(initialState(data));

  useEffect(() => {
    if (!data) return;
    setUserDetailsState(initialState(data));
  }, [data]);

  const toggleDetailsEdit = (
    section: keyof ReturnType<typeof initialState>,
    toggle: boolean
  ) => {
    if (!toggle) {
      setUserDetailsState(initialState(data));
      return;
    }
    setUserDetailsState((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        isEdit: toggle,
      },
    }));
  };

  const updateDetailsValue = <
    Section extends keyof ReturnType<typeof initialState>,
    Field extends keyof ReturnType<typeof initialState>[Section]
  >(
    section: Section,
    field: Field,
    value: string
  ) => {
    setUserDetailsState((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const submitUserDetails = async () => {
    try {
      const firstName = userDetailsState?.name?.firstName;
      const lastName = userDetailsState?.name?.lastName;
      if (!firstName || !lastName) {
        displayToast("Details shouldn't be empty", {}, "error");
        return;
      }
      await update({
        variables: {
          input: {
            firstName,
            lastName,
          },
        },
      });
      toggleDetailsEdit("name", false);
      displayToast("Updated Details Successfully");
    } catch (err) {
      displayToast("Something went wrong", {}, "error");
      console.log(err);
    }
  };

  return {
    userDetailsState,
    toggleDetailsEdit,
    updateDetailsValue,
    submitUserDetails,
    loadingMutateDetails: loading,
  };
};

export default useUserDetails;
