import {
  Button,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { brighthiveTheme } from "../theme/theme";
import { CorporateFare } from "@mui/icons-material";

export enum classes {
  label,
}

export const ButtonPrimary = styled(Button)`
  background-color: ${brighthiveTheme.palette.primary.main};
  color: ${brighthiveTheme.palette.primary.darkest};
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: none;
  min-width: 1px;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.dark};
    color: ${brighthiveTheme.palette.common.white};
    border-color: transparent;
    box-shadow: none;
  }
`;

export const ButtonDefault = styled(Button)`
  color: ${brighthiveTheme.palette.gray.dark};
  border-radius: 3px;
  border: 1px solid ${brighthiveTheme.palette.action.disabledBackground};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;

  &.small {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  &.icn-large {
    svg {
      width: 26px;
      height: 26px;
    }
  }

  &.active,
  &:hover {
    border-color: ${brighthiveTheme.palette.primary.dark};
    background-color: ${brighthiveTheme.palette.primary.active};
    color: ${brighthiveTheme.palette.primary.dark};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const CustomTextField = ({ ...props }: TextFieldProps) => (
  <TextField
    inputProps={{
      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
    }}
    size="small"
    {...props}
  />
);

export const StyledTextField = styled(CustomTextField)``;

export const StyledTypography = styled(Typography)(({ theme, className }) => {
  if (className === classes[classes.label]) {
    return {
      color: theme.palette.gray.dark,
    };
  }
});

export const StyledCorporateFare = styled(CorporateFare)(({ theme }) => ({
  mr: 2,
  color: theme.palette.gray.dark,
  fontSize: "1rem",
  width: "1rem",
  height: "1rem",
}));
